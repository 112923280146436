import { COLLECTIONS, setIDOnCreate } from '@/firebase/collections'
import { firebase } from '@/firebase'
import {
  IAccount, IAccountPrices, IAccountTrialsAndDiscounts, IStat,
} from '@/types'

export default class ProAccount implements IAccount {
    id: string
    numberID: number
    nextCaseNumber: number
    nextQRLabelNumber: number
    images: {
      mainLogo: string
      emailLogo: string
    }
    createdDate: number

    // About
    name: string
    baseCountry: string
    address: string
    postalCode: string
    city: string
    contactPerson: {
      name: string
      phoneNumber: string
      email: string
    }
    companyInitials: string

    // Billing
    isInvoicePaymentAllowed: boolean
    isInvoicePaymentSelected: boolean
    billingInfo: {
      customerType: 'business',
      countryCode: 'DK',
      fullName: string,
      email: string,
      phone: string,
      address: string,
      postalCode: string,
      city: string,
      company: string,
      vatNumber: string,
      eanNumber: string,
      att: string,
    }

    // Handover
    pickUpPoints:{
      placement: string,
      address: {
        address: string,
        postalCode: string,
        city: string,
      }
    }[]

    // Settings
    autoOrderCPFromCPScreening: boolean
    autoOrderPAHFromCH: boolean
    isActive: boolean
    useSamplingID: boolean
    canUseSampler: boolean
    canUseMapper: boolean
    hidePrices: boolean
    useMandatoryManualCaseNumbers: boolean
    allowedProjectTypeIDs: string[]
    autoSelectedProjectOptionIDs: string[]
    canUseCustomAddress: boolean

    // Labs
    allowMilvaEuroFins: boolean
    billPerRequisition: boolean
    primaryLabID: string
    analysisDeliveryTimeOptionID: string
    euroFinsNumber: string
    hojvangNumber: string
    analysisReportEmail: string

    // Prices
    prices: IAccountPrices
    trialsAndDiscounts:IAccountTrialsAndDiscounts

    // Stats
    overallStats: IStat
    monthlyStats: {
      [monthYear: string]: IStat
    }
    overallStatsV3: IStat
    monthlyStatsV3: {
      [monthYear: string]: IStat
    }

    // Legacy
    statusHistory?: {
      state: boolean
      timestamp: firebase.firestore.Timestamp | number
    }[]
    nextScreeningNumber: number

    constructor(data?: IAccount) {
      // General
      this.id = setIDOnCreate(data?.id, COLLECTIONS.DB_ACCOUNTS)
      this.numberID = data?.numberID ?? 0
      this.nextCaseNumber = data?.nextCaseNumber ?? 1337
      this.nextQRLabelNumber = data?.nextQRLabelNumber ?? 1
      this.images = {
        mainLogo: data?.images?.mainLogo ?? '',
        emailLogo: data?.images?.emailLogo ?? '',
      }
      this.createdDate = data?.createdDate ?? Date.now()

      // About
      this.name = data?.name ?? ''
      this.baseCountry = data?.baseCountry ?? 'DK'
      this.address = data?.address ?? ''
      this.postalCode = data?.postalCode ?? ''
      this.city = data?.city ?? ''
      this.contactPerson = {
        name: data?.contactPerson?.name ?? '',
        phoneNumber: data?.contactPerson?.phoneNumber ?? '',
        email: data?.contactPerson?.email ?? '',
      }
      this.companyInitials = data?.companyInitials ?? ''

      // Settings
      // - Generel
      this.isActive = data?.isActive ?? true
      this.useMandatoryManualCaseNumbers = data?.useMandatoryManualCaseNumbers ?? false

      // - Sampler
      this.canUseSampler = data?.canUseSampler ?? false
      this.canUseCustomAddress = data?.canUseCustomAddress ?? false
      this.hidePrices = data?.hidePrices ?? false
      this.autoOrderCPFromCPScreening = data?.autoOrderCPFromCPScreening ?? false
      this.autoOrderPAHFromCH = data?.autoOrderPAHFromCH ?? false

      // - Mapper
      this.canUseMapper = data?.canUseMapper ?? false
      this.allowedProjectTypeIDs = data?.allowedProjectTypeIDs ?? []
      this.autoSelectedProjectOptionIDs = data?.autoSelectedProjectOptionIDs ?? []
      this.useSamplingID = data?.useSamplingID ?? false

      // Billing
      this.isInvoicePaymentAllowed = data?.isInvoicePaymentAllowed ?? false
      this.isInvoicePaymentSelected = data?.isInvoicePaymentSelected ?? false
      this.billingInfo = {
        customerType: data?.billingInfo?.customerType ?? 'business',
        countryCode: data?.billingInfo?.countryCode ?? 'DK',
        fullName: data?.billingInfo?.fullName ?? '',
        email: data?.billingInfo?.email ?? '',
        phone: data?.billingInfo?.phone ?? '',
        address: data?.billingInfo?.address ?? '',
        postalCode: data?.billingInfo?.postalCode ?? '',
        city: data?.billingInfo?.city ?? '',
        company: data?.billingInfo?.company ?? '',
        vatNumber: data?.billingInfo?.vatNumber ?? '',
        eanNumber: data?.billingInfo?.eanNumber ?? '',
        att: data?.billingInfo?.att ?? '',
      }

      // Handover
      this.pickUpPoints = data?.pickUpPoints ?? []

      // Labs
      this.allowMilvaEuroFins = data?.allowMilvaEuroFins ?? false
      this.billPerRequisition = data?.billPerRequisition ?? false
      this.primaryLabID = data?.primaryLabID ?? 'euroFins'
      this.analysisDeliveryTimeOptionID = data?.analysisDeliveryTimeOptionID ?? 'ADO-6'
      this.euroFinsNumber = data?.euroFinsNumber ?? ''
      this.hojvangNumber = data?.hojvangNumber ?? ''
      this.analysisReportEmail = data?.analysisReportEmail ?? ''

      // Prices
      this.trialsAndDiscounts = {
        freeReports: {
          type: data?.trialsAndDiscounts?.freeReports?.type ?? 'counter',
          value: data?.trialsAndDiscounts?.freeReports?.value ?? 0,
          logs: data?.trialsAndDiscounts?.freeReports?.logs ?? [],
        },
        tieredReports: {
          isActive: data?.trialsAndDiscounts?.tieredReports?.isActive ?? false,
          analysisCount: data?.trialsAndDiscounts?.tieredReports?.analysisCount ?? 0,
          price: data?.trialsAndDiscounts?.tieredReports?.price ?? 0,
          logs: data?.trialsAndDiscounts?.tieredReports?.logs ?? [],
        },
      }

      this.prices = {
        other: {
          projectFee: {
            type: data?.prices?.other?.projectFee?.type ?? 'price',
            value: data?.prices?.other?.projectFee?.value ?? 0,
            isActive: data?.prices?.other?.projectFee?.isActive ?? false,
            logs: data?.prices?.other?.projectFee?.logs ?? [],
          },
        },
        matAnalyses: {
          'ST-1': {
            type: data?.prices?.matAnalyses?.['ST-1']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-1']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-1']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-1']?.logs ?? [],
          },
          'ST-2': {
            type: data?.prices?.matAnalyses?.['ST-2']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-2']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-2']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-2']?.logs ?? [],
          },
          'ST-3': {
            type: data?.prices?.matAnalyses?.['ST-3']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-3']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-3']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-3']?.logs ?? [],
          },
          'ST-4': {
            type: data?.prices?.matAnalyses?.['ST-4']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-4']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-4']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-4']?.logs ?? [],
          },
          'ST-5': {
            type: data?.prices?.matAnalyses?.['ST-5']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-5']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-5']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-5']?.logs ?? [],
          },
          'ST-6': {
            type: data?.prices?.matAnalyses?.['ST-6']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-6']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-6']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-6']?.logs ?? [],
          },
          'ST-7': {
            type: data?.prices?.matAnalyses?.['ST-7']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-7']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-7']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-7']?.logs ?? [],
          },
          'ST-8': {
            type: data?.prices?.matAnalyses?.['ST-8']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-8']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-8']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-8']?.logs ?? [],
          },
          'ST-9': {
            type: data?.prices?.matAnalyses?.['ST-9']?.type ?? 'price',
            value: data?.prices?.matAnalyses?.['ST-9']?.value ?? 0,
            isActive: data?.prices?.matAnalyses?.['ST-9']?.isActive ?? false,
            logs: data?.prices?.matAnalyses?.['ST-9']?.logs ?? [],
          },
        },
        analysisDeliveryTime: {
          'ADO-1': {
            type: data?.prices?.analysisDeliveryTime?.['ADO-1']?.type ?? 'multiplier',
            value: data?.prices?.analysisDeliveryTime?.['ADO-1']?.value ?? 0,
            isActive: data?.prices?.analysisDeliveryTime?.['ADO-1']?.isActive ?? false,
            logs: data?.prices?.analysisDeliveryTime?.['ADO-1']?.logs ?? [],
          },
          'ADO-2': {
            type: data?.prices?.analysisDeliveryTime?.['ADO-2']?.type ?? 'multiplier',
            value: data?.prices?.analysisDeliveryTime?.['ADO-2']?.value ?? 0,
            isActive: data?.prices?.analysisDeliveryTime?.['ADO-2']?.isActive ?? false,
            logs: data?.prices?.analysisDeliveryTime?.['ADO-2']?.logs ?? [],
          },
          'ADO-3': {
            type: data?.prices?.analysisDeliveryTime?.['ADO-3']?.type ?? 'multiplier',
            value: data?.prices?.analysisDeliveryTime?.['ADO-3']?.value ?? 0,
            isActive: data?.prices?.analysisDeliveryTime?.['ADO-3']?.isActive ?? false,
            logs: data?.prices?.analysisDeliveryTime?.['ADO-3']?.logs ?? [],
          },
          'ADO-4': {
            type: data?.prices?.analysisDeliveryTime?.['ADO-4']?.type ?? 'multiplier',
            value: data?.prices?.analysisDeliveryTime?.['ADO-4']?.value ?? 0,
            isActive: data?.prices?.analysisDeliveryTime?.['ADO-4']?.isActive ?? false,
            logs: data?.prices?.analysisDeliveryTime?.['ADO-4']?.logs ?? [],
          },
          'ADO-5': {
            type: data?.prices?.analysisDeliveryTime?.['ADO-5']?.type ?? 'multiplier',
            value: data?.prices?.analysisDeliveryTime?.['ADO-5']?.value ?? 0,
            isActive: data?.prices?.analysisDeliveryTime?.['ADO-5']?.isActive ?? false,
            logs: data?.prices?.analysisDeliveryTime?.['ADO-5']?.logs ?? [],
          },
          'ADO-6': {
            type: data?.prices?.analysisDeliveryTime?.['ADO-6']?.type ?? 'multiplier',
            value: data?.prices?.analysisDeliveryTime?.['ADO-6']?.value ?? 0,
            isActive: data?.prices?.analysisDeliveryTime?.['ADO-6']?.isActive ?? false,
            logs: data?.prices?.analysisDeliveryTime?.['ADO-6']?.logs ?? [],
          },
        },
        sampleHandOver: {
          'drop-off-point': {
            type: data?.prices?.sampleHandOver?.['drop-off-point']?.type ?? 'price',
            value: data?.prices?.sampleHandOver?.['drop-off-point']?.value ?? 0,
            isActive: data?.prices?.sampleHandOver?.['drop-off-point']?.isActive ?? false,
            logs: data?.prices?.sampleHandOver?.['drop-off-point']?.logs ?? [],
          },
          'pick-up-point': {
            type: data?.prices?.sampleHandOver?.['pick-up-point']?.type ?? 'price',
            value: data?.prices?.sampleHandOver?.['pick-up-point']?.value ?? 0,
            isActive: data?.prices?.sampleHandOver?.['pick-up-point']?.isActive ?? false,
            logs: data?.prices?.sampleHandOver?.['pick-up-point']?.logs ?? [],
          },
          'custom-address': {
            type: data?.prices?.sampleHandOver?.['custom-address']?.type ?? 'price',
            value: data?.prices?.sampleHandOver?.['custom-address']?.value ?? 0,
            isActive: data?.prices?.sampleHandOver?.['custom-address']?.isActive ?? false,
            logs: data?.prices?.sampleHandOver?.['custom-address']?.logs ?? [],
          },
        },
      }

      // Stats
      this.overallStats = data?.overallStats ?? {}
      this.monthlyStats = data?.monthlyStats ?? {}
      this.overallStatsV3 = data?.overallStatsV3 ?? {}
      this.monthlyStatsV3 = data?.monthlyStatsV3 ?? {}

      // Legacy
      this.statusHistory = data?.statusHistory ?? [
        {
          state: !!data?.isActive,
          timestamp: this.createdDate,
        },
      ]
      this.nextScreeningNumber = data?.nextScreeningNumber ?? 1337
    }

    toJSON(): IAccount {
      return { ...this }
    }
}

